<template>
  <div class="bottom">
    <div class="top">
      <div class="item1" @click="kefu">
        官方客服
      </div>
      <div class="item1" @click="feed">
      意见反馈
    </div>
      <div class="item" @click="scrollTop">
        返回顶部
      </div>

    </div>
    <div class="bottom">
      京ICP备2022034763号

    </div>

  </div>

</template>
<script>
export default {
  name: "bottomMobile",
  methods:{
    kefu(){
      window.location.href = "https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=2957254971134827&webPageTitle=%E5%9C%A8%E7%BA%BF%E5%92%A8%E8%AF%A2";
    },
    feed(){
      this.$router.push("/Feedback")
    },
    scrollTop(){
      // console.log("dingbu")
      window.scrollTo(0, 0);
    }
  }
}
</script>
<style scoped lang="scss">
.bottom{
  margin: 10px 0;
  font-size: 12px;
  background-color: white;
  padding: 20px 20px 2px 20px;
  color:gray;
  .top{
    padding-bottom:10px ;
    border-bottom: #d5d5d5 1px solid;
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    .item1{
      height: 16px;

      border-right: #d5d5d5 1px solid;
      width: 100%;
      text-align: center;
    }
    .item{
      width: 100%;
      text-align: center;
      padding: 0 0 0 0;
    }
  }
  .bottom{
    width: 100%;
    text-align: center;
      padding: 0 0 0 0;

  }
}
</style>
