<template>
  <div id="footer">
    <div v-if="isMobile===false">
      <div  v-if="languageSelect==='zh'">
        <div class="flex">
          <p>北京市怀柔区迎宾南路11号</p>
          <span> | </span>
          <p>北京石由同学信息咨询有限公司版权所有</p>
          <span> | </span>
          <p class="red">
            <a href="https://beian.miit.gov.cn/">京ICP备2022034763号</a>
          </p>
        </div>
      </div>
      <div  v-if="languageSelect==='en'">
        <div class="flex">
          <p>No. 11 Yingbin South Road, Huairou District, Beijing</p>
          <span> | </span>
          <p>Copyright Beijing Shiyou Classmate Information Consulting Co., Ltd</p>
          <span> | </span>
          <p class="red">
            <a href="https://beian.miit.gov.cn/">京ICP备2022034763号</a>
          </p>
        </div>
      </div>
    </div>
    <div v-if="isMobile===true">
      <div  v-if="languageSelect==='zh'">
        <div style="font-size: 14px;text-align: center;margin-top: 3vh;padding:5px;background-color: #fbe4e9">
          <p>北京市怀柔区迎宾南路11号</p>
          <p>北京石由同学信息咨询有限公司版权所有</p>
          <p class="red">
            <a href="https://beian.miit.gov.cn/">京ICP备2022034763号</a>
          </p>
        </div>
      </div>
      <div  v-if="languageSelect==='en'">
        <div style="font-size: 14px;text-align: center;margin-top: 5vh;padding:5px;background-color: #fbe4e9">
          <p>No. 11 Yingbin South Road, Huairou District, Beijing</p>
          <p>Copyright Beijing Shiyou Classmate Information Consulting Co., Ltd</p>
          <p class="red">
            <a href="https://beian.miit.gov.cn/">京ICP备2022034763号</a>
          </p>
        </div>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
#footer {
  background-color: #fbe4e9;
  // padding: 10px 0px;
  margin: 0;
  // height: 82px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  .flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    height: 18px;
    p {
      text-align: center;
      width: fit-content;
      color: #999;
      padding: 0;
      margin: 0;
      font-size: 12px;
    }
    p:nth-child(1),
    p:nth-child(3) {
      font-size: 12px;
      font-family: arial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      line-height: 30px;
    }
    span {
      padding: 0 10px;
      color: #999;
    }
    a {
      color: #999;
    }
  }
}
</style>
<script>

export default {
  data() {
    return {
      isMobile:false,
      languageSelect:'',
    };
  },
  mounted() {
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
  },
  created() {
    if(localStorage.getItem('language')){
      this.languageSelect=localStorage.getItem('language')
    }else{
      this.languageSelect='zh'
    }
    console.log("cdefe"+this.languageSelect)
  },
};
</script>
